@import "../../Styles/theme.scss";

.troubleshooting_container {
  display: flex;
  justify-content: center;
  margin: 24px;
  margin-top: 32px;
  margin-bottom: 24px;
  cursor: pointer;
}

.text {
  margin-left: 6px;
  color: $error1;
  font-weight: $mediumWeight;
}

.troubleshooting_image {
  align-self: center;
  margin: 48px 0;
}

.troubleshooting_question {
  text-transform: none;
  color: $neutral7;
  font-weight: $mediumWeight;
  font-size: $heading5;
}

.troubleshooting_answer {
  color: $neutral5 !important;
}

.hr {
  width: 100%;
  border-top: 1px solid $neutral2;
  margin: 36px 0 24px 0;
  box-sizing: border-box;
}
