@import "../../Styles/theme.scss";

.avatar_container {
  border-radius: 50%;
  min-width: 32px;
  width: 32px;
  height: 32px;
  background-color: $yellow;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-size: $bodySmall;
  font-weight: $mediumWeight;
  line-height: unset;
  cursor: pointer;

  .avatar_initials {
    position: relative;
    color: $neutral7;
    z-index: 0;
  }
}

.avatar_tooltip {
  display: flex;
  flex-direction: flex-start;
  min-width: 150px;
  width: 100%;
}

.avatar_container .avatar_tooltip {
  visibility: hidden;
  text-align: center;
  position: absolute;
  font-size: $bodyMedium;
  bottom: 130%;
  left: 10%;
  opacity: 0;
  transition: opacity 0.5s;
}

.avatar_container:hover .avatar_tooltip {
  visibility: visible;
  opacity: 1;
}

.default_cursor {
  cursor: default;
}

.overlap {
  margin-right: -6px;
}

.avatar_container_small {
  min-width: 30px;
  width: 30px;
  height: 30px;
  font-size: $bodyExtraSmall !important;
}

.avatar_container_large {
  min-width: 38px;
  width: 38px;
  height: 38px;
}

.does_not_match_search_result {
  opacity: 0.2;
}
