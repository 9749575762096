@import "../../../Styles/theme.scss";

.background {
  width: 100vw;
  height: 100vh;
  background-color: $white;
  z-index: 600;
  position: fixed;
  top: 0;
  left: 0;
}

.logo {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -25px;
}

.progressBar {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progressBar_bar {
  width: 210px;
  height: 5px;
  border-radius: 2px;
  background-color: $neutral3;
  position: relative;
}

.progressBar_bar_fill {
  background-color: $primaryTeal;
  width: 0;
  height: 5px;
  border-radius: 2px;
  position: absolute;
  left: 0;
  transition: 0.3s;
}

.progressBar_message {
  font-size: $small-font-size;
  margin-top: 24px;
}
