@import "../../../Styles/theme.scss";

.mainImage {
  width: 80%;
  align-self: center;
}

.door_sensor_top {
  display: flex;
  flex-direction: column;
  flex: 2;
  color: $neutral7;
}
