@import "../../../Styles/theme.scss";

.image {
  width: 134px;
  object-fit: contain;
  align-self: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.top_container {
  display: flex;
  flex-direction: column;
  flex: 2;
  h2,
  p,
  ol,
  li,
  label {
    color: $neutral7 !important;
  }
}
