@import "../../Styles/theme.scss";

.modal {
  padding: 0;
  height: 70%;
  top: 15%;
  background-color: $white;
}

.addServiceUser {
  width: 100%;
  max-height: 100%;
  border-radius: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.addServiceUser_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  background-color: $denim-blue;
  padding: 12px;
  color: $white;
  div {
    flex: 1;
  }
}
.addServiceUser_filters {
  padding: 12px;
  box-sizing: border-box;
}

.addServiceUser_SUs {
  overflow-y: auto;
  max-height: 100%;
  margin-bottom: 16px;
}

.addServiceUser_SU {
  border: 0;
  border-top: 2px solid $neutral3;
}
