@import "../../Styles/theme.scss";

.user_email {
  font-weight: $mediumWeight;
}
.fail_mobile_only {
  display: none;
}
.pw_reset_container {
  position: relative;
  top: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  border: 1px solid $neutral7;
  border-radius: 4px;
  width: 310px;
  height: auto;
}

.success_info {
  margin-top: 16px;
}

.pw_reset_success_container {
  @extend .pw_reset_container;
  border: 1px solid $expected2;
}

.pw_reset_fail_container {
  @extend .pw_reset_container;
  border: 1px solid $error2;
}

.inner_container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px;
}
.top_container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
}
.bottom_container {
  display: flex;
  align-items: flex-start;
}

.icon, .text {
  align-items: flex-start;
  align-content: center;
  align-self: center;
}

.text {
  margin-left: 10px;
  line-height: 22px;
}

.title,
.description {
  color: $neutral7;
}

.title {
  font-weight: 500;
  font-size: 16px;
}

.description {
  font-weight: 400;
  font-size: 14px;
  margin-top: 8px;
}

@media (max-width: 420px) {
  .fail_mobile_only {
    display: inline-block;
  }
  .pw_reset_container {
    width: 100%;
    height: auto;
  }
  .to_whom, .user_email {
    display: none !important;
  }
}