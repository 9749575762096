.steps {
  display: flex;
  flex-direction: column;
  list-style: decimal;
  margin-left: 24px;
  margin-bottom: 24px;
  text-align: start;
  li {
    margin-top: 12px;
  }
}
