@import "../../Styles/theme.scss";

.page {
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 30%;
  min-width: 300px;
}

.title {
  font-size: 26px;
  color: $neutral7;
  margin-bottom: 13px;
}

.qrContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10%;
}

.qrInnerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.appStoreLink {
  color: $brandBlue6;
}
