@import "../../Styles/theme.scss";

.table {
  display: flex;
  border: 1px solid $neutral2;
  border-radius: 4px;
  flex-direction: column;
  color: $neutral7;
  line-height: 18px;
}

.table_header {
  display: flex;
  width: 100%;
  align-items: center;
  background-color: $neutral1;
  th {
    padding: 18px 0 18px 22px;
    font-size: $bodySmall;
    font-weight: $mediumWeight;
    width: 120px;
    max-width: 120px;
    box-sizing: border-box;
  }
}

.table_header_element {
  font-size: $bodySmall;
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 150px;
  min-width: 90px;
}

.table_header_sortable {
  @extend .table_header_element;
  cursor: pointer;
  span {
    margin-right: 8px;
  }
}

.table_header_gap {
  flex: 1;
}

.table_header_buttons {
  min-width: 428px;
  margin-right: 0 !important;
  th {
    flex: none;
  }
}
