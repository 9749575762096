@import "../../Styles/theme.scss";

.list_container_lrg,
.list_container_sm {
  display: flex;
  align-items: center;
  border-radius: none !important;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid $primaryTeal;
  border-top: 0;
  padding: 12px;
  background-color: $white;
}

.list_container_sm {
  display: none;
}

.list_element_lrg {
  flex: 1;
}

.staff_member_lrg {
  display: flex;
  align-items: center;
}

.staff_details {
  margin-left: 12px;
  font-size: $baseFontSize;
  margin-left: 6px;
  margin-top: 2px;
}

// Make sure to coorindate this with the Staff component media query
@media (max-width: 700px) {
  .list_container_lrg {
    display: none;
  }

  .list_container_sm {
    display: flex;

    .staff_member_sm {
      font-weight: bold;
    }
  }
}
