@import "../../../Styles/theme.scss";

.page {
  padding: $desktop-padding;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $neutral1;
  align-items: flex-start !important;
}

.card_left {
  width: 100%;
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.logo {
  object-fit: contain;
  align-self: center;
  margin-top: 67px;
  margin-bottom: 20px;
}

.card_right {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.flex_dir_column {
  flex-direction: column;
}

.content {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
  justify-content: space-between;
  color: $neutral7;
  h2 {
    font-size: $heading2;
    margin-top: 12px;
    margin-bottom: 0;
  }
  h3 {
    text-transform: none;
    font-size: $heading4;
    font-weight: $mediumWeight;
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.spinner {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    margin-bottom: 12px;
  }
}

.waiting {
  text-align: center;
  margin-bottom: 12px;
}

@media (max-width: $mobile-break-point) {
  .page {
    background-color: $white;
    padding: 0;
  }
  .card_right {
    width: 100%;
    flex: 1;
    justify-content: space-between;
  }
  .card_left {
    display: none;
  }
}
