/* 
Measures
*/
$header-height: 62px;
$side-drawer-width: 62px;
$mobile-break-point: 600px;
$tablet-sm-break-point: 768px;
$tablet-break-point: 900px;
$laptop-break-point: 1024px;
$mobile-padding: 12px;
$tablet-padding: 21px;
$desktop-padding: 42px;
$pdf-padding: 42px;
$reduced-line-height: 1.3rem;

// OLD FONT SIZES
// Fonts Sizes equivalent to a major third scale: https://type-scale.com/
$small-font-size: 0.8rem;
$heading-1: 1.953rem;
$heading-2: 1.563rem;
$heading-3: 1.25rem;
$heading-4: 16px;
$heading-5: $small-font-size;
$heading-6: 0.64rem;
$heading-7: 0.512rem;

// New Spacing
$spacing-xxs: 4px;
$spacing-xs: 8px;
$spacing-s: 12px; // was 16px;
$spacing-m: 16px; // was 24px;
$spacing-l: 24px;
$spacing-xl: 32px;
$spacing-xxl: 48px;

/*
Brand approved colours - only use these colours
*/

// Neutrals - brand approved
$white: #ffffff;
$neutral1: #fbfafa;
$neutral2: #f2f2f2;
$neutral3: #d7d9d9;
$neutral4: #878e8d;
$neutral5: #6e7473;
$neutral6: #555a5a;
$neutral7: #0c0d0d;

// Primary colours - brand approved
$primary1: #d9f0ed;
$primary2: #c7e9e5;
$primary3: #a6dcd5;
$primary4: #8dbbb5;
$primary5: #769c97;
$primary6: #4b6360;

// Secondary colours - brand approved
$secondary1: #ebeff9;
$secondary2: #abb8e5;
$secondary3: #3b5bc2;

// Risk / Error - brand approved
$risk1: #fff2f1;
$risk2: #ffd8d4;
$risk3: #e67369;
$risk4: #cc665e;
$risk5: #bf6058;
$risk6: #994d46;

// Unexpected / Warning - brand approved
$unexpected1: #fff8ea;
$unexpected2: #fee09c;
$unexpected3: #fcbc28;

// Expected / Success - brand approved
$expected1: #f2f9ea;
$expected2: #7dc629;

// Support - brand approved
$support1: #fefef6;
$support2: #f0f0a0;

/*
Old colours - do not use these
*/

$hallwaySensor: #df9e8a;
$bedroomSensor: #a6cee3;
$smartPlug: #b2df8a;
$bathroomSensor: #2a27ae;
$flamingo: #eb5757;
$denim-blue: #2570e9;

// These are the risk status colours
$unexpected: #f69f60;
$expected: #3fcd7f;
$unexpectedHover: #ffb878;

$orange: #f2994a;
$lime-green: #95de64;
$cornflower-blue: #40a9ff;
$cyan-5: #36cfc9;
$sand: #f5f1e5;
$blue-green: #a5cdc8;
$forest-green: #46876e;

$oldprimary1: #e6f7ff;
$oldprimary2: #bae7ff;
$oldprimary3: #91d5ff;

$primaryCoral: #f55a5a;
$primaryTeal: #143c46;
$teal-opaque: rgba(20, 60, 70, 0.5);

// behaviours
$independence: #bede64;

// success
$success1: #72cf93;
$success2: #6ec48b;

// error
$error1: #e98888;
$error2: #e15b5b;
$error3: #d93030;

// Bathroom Events
$bathing: rgba(105, 192, 255, 0.65); // #69C0FF65;

$softCyan: #56ccf2;
$cyan: #56ccf240;

// too cold or too hot
$tooCold: #bde6ff;
$tooHot: #ed9d9d;

// opaque
$opaque: rgba(0, 0, 0, 0.2);

// Sticker colours for installation
$stickerYellow: rgb(250, 200, 0);
$stickerRed: rgb(191, 37, 37);
$stickerBlue: rgb(0, 104, 161);
$stickerGreen: rgb(0, 104, 49);

$green: #50c378;
$green6: #a6dbd5;
$green5: #b6e2dd;
$green3: #d9f0ed;
$yellow: #f0f0a0;
$brandYellow: #fefef6;
$brandYellow1: #f5f5bf;
$brandYellow900: #656543;
$brandBlack: #1e2524;
$brandBlue: #3552af;
$brandBlue1: #ebeff9;
$brandBlue5: #5e79cd;
$brandBlue6: #3b5bc2;
$brandBlue3: #abb8e5;
$brandGreen9: #5f7d79;
$brandOrange: #fef3ef;
$brandOrange1: #f5ab90;
$dangerRed: #ff8075;

$atrisk1: #fff2f1;
$atrisk2: #e67369;
$atrisktag: #ffd8d4;

/*
Fonts
*/
$headerBody: 14px;
$headerBodySmall: 11.7px;
$heading1: 38px;
$heading2: 32px;
$heading3: 26px;
$heading4: 24px;
$heading5: 22px;
$subHeading: 20px;
$baseFontSize: 16px;
$body: 18px;
$bodyMedium: 16px;
$bodySmall: 14px;
$bodyExtraSmall: 12px;
$bodyExtraExtraSmall: 10px;
$caption: 14px;

/*
Font weights
*/
$light: 300;
$regular: 400;
$mediumWeight: 500;
$semiBold: 600;
$bold: 700;

/*
Line hieghts
*/
$superWide: 36px;
$extraWide: 26px;
$wide: 22px;
$mediumWide: 20px;
$medium: 18px;
$mediumThin: 16px;
$thin: 14px;
$extraThin: 12px;

:export {
  /*
  Colours
  */

  hallwaySensor: $hallwaySensor;
  bedroomSensor: $bedroomSensor;
  smartPlug: $smartPlug;
  bathroomSensor: $bathroomSensor;
  green: $green;
  flamingo: $flamingo;

  // These are the risk status colours
  unexpected: $unexpected;
  expected: $expected;
  unexpectedHover: $unexpectedHover;

  orange: $orange;

  oldprimary1: $oldprimary1;
  oldprimary2: $oldprimary2;
  oldprimary3: $oldprimary3;

  // Neutrals
  neutral1: $neutral1;
  neutral2: $neutral2;
  neutral3: $neutral3;
  neutral4: $neutral4;
  neutral5: $neutral5;
  neutral6: $neutral6;
  neutral7: $neutral7;

  // Primary colours
  primary1: $primary1;
  primary2: $primary2;
  primary3: $primary3;
  primary4: $primary4;
  primary5: $primary5;
  primary6: $primary6;

  // Secondary colours
  secondary1: $secondary1;
  secondary2: $secondary2;
  secondary3: $secondary3;

  // Risk / Error
  risk1: $risk1;
  risk2: $risk2;
  risk3: $risk3;
  risk4: $risk4;
  risk5: $risk5;
  risk6: $risk6;

  // Unexpected / Warning
  unexpected1: $unexpected1;
  unexpected2: $unexpected2;
  unexpected3: $unexpected3;

  // Expected / Success
  expected1: $expected1;
  expected2: $expected2;

  // Support
  support1: $support1;
  support2: $support2;

  primarycoral: $primaryCoral;
  primaryTeal: $primaryTeal;
  teal_opaque: $teal-opaque;
  denim_blue: $denim-blue;
  lime_green: $lime-green;
  cornflower_blue: $cornflower-blue;
  sand: $sand;
  blue_green: $blue-green;
  forest_green: $forest-green;

  softCyan: $softCyan;
  cyan: $cyan;

  // too cold or too hot
  tooCold: $tooCold;
  tooHot: $tooHot;

  // behaviours
  independence: $independence;

  // opaque
  opaque: $opaque;

  // success
  success1: $success1;
  success2: $success2;

  // error
  error1: $error1;
  error2: $error2;
  error3: $error3;

  // Bathroom
  bathing: $bathing;

  // Our greys
  white: $white;

  // Sticker colours for installation
  stickerYellow: $stickerYellow;
  stickerRed: $stickerRed;
  stickerBlue: $stickerBlue;
  stickerGreen: $stickerGreen;

  // Brand
  green6: $green6;
  green5: $green5;
  green3: $green3;
  yellow: $yellow;
  brandBlack: $brandBlack;
  brandBlue1: $brandBlue1;
  brandBlue3: $brandBlue3;
  brandBlue5: $brandBlue5;
  brandBlue6: $brandBlue6;
  brandGreen9: $brandGreen9;
  brandYellow: $brandYellow;
  brandYellow1: $brandYellow1;
  brandYellow900: $brandYellow900;
  brandOrange: $brandOrange;
  brandOrange1: $brandOrange1;
  dangerRed: $dangerRed;

  atrisk1: $atrisk1;
  atrisk2: $atrisk2;
  atrisktag: $atrisktag;

  //Spacing
  spacing-xxs: $spacing-xxs;
  spacing-xs: $spacing-xs;
  spacing-s: $spacing-s;
  spacing-m: $spacing-m;
  spacing-l: $spacing-l;
  spacing-xl: $spacing-xl;
  spacing-xxl: $spacing-xxl;

  /*
  Fonts
  */
  headerBody: $headerBody;
  headerBodySmall: $headerBodySmall;
  heading1: $heading1;
  heading2: $heading2;
  heading3: $heading3;
  heading4: $heading4;
  heading5: $heading5;
  subHeading: $subHeading;
  baseFontSize: $baseFontSize;
  body: $body;
  bodyMedium: $bodyMedium;
  bodySmall: $bodySmall;
  bodyExtraSmall: $bodyExtraSmall;
  bodyExtraExtraSmall: $bodyExtraExtraSmall;
  caption: $caption;
  small_font_size: $small-font-size;

  /*
  Font weights
  */
  light: $light;
  regular: $regular;
  mediumWeight: $mediumWeight;
  semiBold: $semiBold;
  bold: $bold;

  /*
  Line hieghts
  */
  superWide: $superWide;
  extraWide: $extraWide;
  wide: $wide;
  mediumWide: $mediumWide;
  medium: $medium;
  mediumThin: $mediumThin;
  thin: $thin;
  extraThin: $extraThin;
}
