@import "../../Styles/theme.scss";

.pill {
  background-color: $sand;
  width: 55px;
  height: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 4px;
  box-sizing: border-box;
  position: absolute;
  border-radius: 9px;
  bottom: 3px;
  right: 3px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  color: $primaryTeal;
  div {
    margin-left: 4px;
    line-height: 1;
    font-weight: bold;
    font-size: 10px;
  }
}

.large {
  width: 80px;
  height: 30px;
  padding-bottom: 5px;
  border-radius: 18px;
  bottom: 6px;
  right: 6px;
  div {
    margin-left: 6px;
    font-size: 16px;
  }
}
