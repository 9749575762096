@import "../../Styles/theme.scss";

.userBio {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: $desktop-padding;
  box-sizing: border-box;
  border-bottom: 3px solid $primaryTeal;
  color: $primaryTeal;
}

.userBio_left {
  display: flex;
}

.userBio_info {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
}

.userBio_info_name {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bolder;
}

.userBio_info_id {
  background-color: $forest-green;
  color: $white;
  border-radius: 12px;
  padding: 8px 8px 6px 8px;
  align-self: start;
  line-height: 1;
  margin-bottom: 12px;
  font-weight: bold;
}

.userBio_info_data {
  display: flex;
  flex-direction: column;
  color: $primaryTeal;
  width: 100%;
}

.userBio_info_data_item {
  display: flex;
  align-items: center;
  line-height: 1;
  span {
    margin-top: 4px;
  }
  svg {
    margin-right: 6px;
  }
}

@media (max-width: $mobile-break-point) {
  .userBio {
    flex-direction: column;
    padding: $mobile-padding;
  }
  .userBio_left {
    align-items: center;
    width: 100%;
  }
  .userBio_info_data {
    margin-top: 12px;
  }
  .userBio_info_name {
    font-size: $heading-2;
    margin-bottom: 6px;
    font-weight: 400;
  }
  .userBio_info_id {
    border-radius: 8px;
    padding: 6px 6px 4px 6px;
    font-size: $small-font-size;
    margin-bottom: 0;
  }
  .userBio_button {
    align-self: flex-start;
  }
}
