@import "../../Styles/theme.scss";

.insight {
  display: flex;
  flex-direction: column;
  p {
    font-size: $bodyMedium;
  }
}

.insight_top {
  display: flex;
  align-items: center;
  font-weight: $mediumWeight;
  font-size: $subHeading;
  margin-bottom: 24px;
  flex-wrap: wrap !important;
  svg {
    margin-right: 12px;
  }
}

.underline {
  text-decoration: underline;
}

.insight_data {
  display: flex;
  align-items: center;
  font-weight: $mediumWeight;
  font-size: $heading1;
  margin-bottom: 24px;
}

.insight_amount {
  font-size: $heading1;
  color: $brandBlack;
  font-weight: $mediumWeight;
}

.insight_no_change {
  @extend .insight_amount;
  margin-left: 0px;
  line-height: 30px;
}

.insight_amount_change {
  @extend .insight_amount;
  line-height: 30px;
  margin-left: 12px;
}
