@import "../../Styles/theme.scss";

.icon {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  box-sizing: border-box;
  border: 2px solid $neutral2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $yellow;
  cursor: pointer;
  div {
    color: $neutral7;
    margin-top: 3px;
    font-size: 12px;
  }
}

.profile_image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
