@import "../../Styles/theme.scss";

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 2px solid $expected;
  padding-bottom: 6px;
  margin-bottom: 12px;
}

.heading_text {
  font-size: $heading4;
}