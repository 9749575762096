@import "../../Styles/theme.scss";

.page {
  padding: 24px;
}

.details {
  margin-top: 2em;
  margin-left: 2em;
  margin-right: 2em;
}

.details[open] summary ~ * {
  animation: sweep 0.5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-left: -2em;
  }
  100% {
    opacity: 1;
    margin-left: 0;
  }
}

h3 {
  display: inline;
}

.questions {
  width: 100%;
}

.question {
  margin-left: 3em;
  font-weight: bold;
}

.answer {
  margin-left: 4em;
  padding-bottom: 2em;
}

.answer ol {
  list-style: circle inside;
}

.answer a {
  color: $primaryCoral;
}

.privacyButton {
  padding: 0;
  margin-bottom: 2px;
}
