@import "../../Styles/theme.scss";

.buttons {
  display: flex;
  margin-top: 24px;
  width: 100%;
  flex-direction: column-reverse;
}

.error {
  font-size: $bodySmall;
  color: $error3;
}

.buttons_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    width: 40%;
  }
}

.buttons_button {
  margin: 10px 0;
}
