@import "../../Styles/theme.scss";

.sort {
  background-color: $white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 400;
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.sort_line {
  display: flex;
  justify-content: space-between;
  padding: 13px 26px;
}

.sort_header {
  @extend .sort_line;
  border-bottom: 1px solid $neutral1;
}

.sort_option {
  @extend .sort_line;
}
