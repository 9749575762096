@import "../../Styles/theme.scss";

// Avatar Tooltip
.avatar_staff_tooltip_container {
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 2px 2px 2px 0px;
}

.avatar_background_green {
  background-color: $yellow;
}

.avatar_background_blue {
  background-color: $yellow;
}

.avatar_name {
  padding: 4px 8px;
}

// TextStaffToolTip
.text_staff_tooltip_container {
  background: $white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  height: 150px;
  overflow: scroll;
}

.staff_item {
  padding: 6px;
  font-size: $bodyMedium;
  color: $neutral7;
}

.staff_does_not_match_results {
  color: $neutral4;
}
