@import "../../Styles/theme.scss";

.report_button {
  white-space: nowrap;
  background-color: $green5;
  border-color: $green5;
  color: $neutral7;
}

.report_button_generate {
  @extend .report_button;
  min-width: 167px !important;
}

.mobile_report {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid $neutral2;
  background-color: $white;
  border-radius: 4px;
  margin-bottom: 18px;
}

.mobile_report_details {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.mobile_report_name {
  font-weight: $mediumWeight;
}

.mobile_report_user {
  color: $primary6;
  font-size: $bodySmall;
  align-self: flex-start;
}

.mobile_report_dates {
  font-size: $bodyExtraSmall;
  color: $neutral5;
}

.mobile_report_buttons {
  display: flex;
  justify-content: center;
}

.mobile_report_buttons_generate {
  display: flex;
  justify-content: center;
  width: 50%;
  border-top: 1px solid $neutral2;
  padding: 13px;
  background-color: green5;
  &:disabled {
    background-color: $neutral1;
    color: $primary6;
  }
}

.mobile_report_buttons_delete {
  @extend .mobile_report_buttons_generate;
  border-left: 1px solid $neutral2;
  color: $dangerRed;
  &:disabled {
    background-color: $white;
    color: $dangerRed;
  }
}

.report {
  display: flex;
  width: 100%;
  border-bottom: 1px solid $neutral2;
  &:hover {
    background-color: $neutral1;
    cursor: pointer;
  }
}

.report:hover {
  background-color: $neutral1;
}

.report_element {
  display: flex;
  padding: 23px 0 23px 22px;
  font-size: $bodySmall;
  width: 120px;
  box-sizing: border-box;
}

.report_name {
  @extend .report_element;
  font-weight: $mediumWeight;
}

.report_user {
  @extend .report_element;
  color: $primary6;
  cursor: pointer;
}

.report_gap {
  flex: 1;
}

.buttons {
  flex: 1;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
  align-self: center;
  position: relative;
}

.buttons_generate {
  margin-right: 10px !important;
}
