@import "../../../Styles/theme.scss";

.confirm_card {
  box-sizing: border-box;
  max-width: 350px;
  background: $neutral1;
  border: 1px solid #ededed;
  border-radius: 4px;
  text-align: left;
  width: 100%;
  padding: 24px;
}

.confirm_name {
  font-weight: $bold;
  font-size: $bodyMedium;
  color: $neutral7;
  margin-bottom: 10px;
}

.confirm_address {
  font-weight: $regular;
  font-size: $bodyMedium;
  color: $neutral7;
}

.page {
  padding: 0;
  padding: $desktop-padding;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $neutral1;
  font-family: Inter;
  align-items: flex-start !important;
}

.card_left {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 73px;
  object-fit: contain;
}

.card_right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checking_su_details {
  width: 100%;
}

.not_checking_su_details {
  width: 50%;
}

.content {
  width: 100%;
  padding: 24px;
  display: flex;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
}

.select_SU {
  display: flex;
  flex: 2;
  flex-direction: column;
  text-align: left;
  align-items: center;
  width: 100%;
  margin-top: 48px;
  margin-bottom: 40px;
  label {
    font-size: $bodySmall;
    color: $neutral7;
    width: 100%;
  }
}

// Confirm SU Details
.is_checking_header {
  font-weight: $mediumWeight;
  font-size: $heading2;
  line-height: normal;
  color: $neutral7;
  margin-bottom: 38px;
  min-width: 350px;
  max-width: 350px;
}

.select_SU_header {
  font-weight: $mediumWeight;
  font-size: $heading2;
  color: $neutral7;
  margin-top: 0;
  margin-bottom: 48px;
}

.two_buttons {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

@media (max-width: $mobile-break-point) {
  // The rest
  .page {
    background-color: $white;
    padding: 0;
  }
  .card_left {
    display: none;
  }
  .card_right {
    width: 100%;
    flex: 1;
    justify-content: space-between;
  }
  .select_SU {
    align-items: flex-start;
  }
  .buttons {
    width: 100%;
    margin-bottom: 10%;
  }
  .two_buttons {
    flex-direction: column;
    justify-content: space-between;
  }
}

@media (max-width: 400px) {
  // Confirm SU Details
  .two_buttons {
    flex-direction: column-reverse !important;
    justify-content: center !important;
  }
  .is_checking_header {
    min-width: unset;
    max-width: unset;
    width: 100%;
  }

  // the rest
  .select_SU {
    align-items: flex-start;
  }
}
