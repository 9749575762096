@import "../../Styles/theme.scss";

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: 250px;
  margin-right: 24px;
  margin-bottom: 24px;
  padding: 18px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: $small-font-size;
}

.card_top {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.card_right {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  line-height: $reduced-line-height;
}

.card_right_name {
  margin-bottom: 12px;
  font-size: $heading3;
}

.card_right_id {
  background-color: $blue-green;
  border-radius: 12px;
  padding: 4px 12px 2px;
  align-self: start;
  font-size: 14px;
}

.bold {
  font-weight: bold;
}

.staff {
  display: flex;
  height: 24px;
}

.staff_staff {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

@media (max-width: $mobile-break-point) {
  .card {
    margin: 6px 0;
    width: 100%;
  }
}
