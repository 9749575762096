@import "../../Styles/theme.scss";

.installation_su_details {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $neutral2;
  padding-bottom: 20px;
  width: 100%;
  justify-content: space-between;
  background-color: $white;
}

.left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.su_details_icon {
  width: 46px;
  height: 46px;
  border-radius: 23px;
  background-color: $neutral2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}

.su_details_name {
  font-size: $body;
  font-weight: $mediumWeight;
  color: $neutral7;
}

.su_details_id {
  color: $neutral5;
}

.right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  font-weight: $regular;
  font-size: $bodySmall;
  color: $neutral5;
}

.disabled {
  cursor: not-allowed;
}

.pointer {
  cursor: pointer;
}

.connections_hint {
  margin-right: 16px;
}

@media (max-width: $mobile-break-point) {
  .installation_su_details {
    padding: 27px;
    width: unset;
  }
}
