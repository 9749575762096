@import "../../../Styles/theme.scss";

.page {
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.card {
  display: flex;
  box-sizing: border-box;
  position: relative;
  color: $neutral7;
}

.card_left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 73px;
  object-fit: contain;
}

.card_right {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.instructions {
  margin-top: 24px;
  h2 {
    margin-top: 0;
  }
}

.main_title {
  font-size: 32px;
}
.secondary_title {
  font-size: 22px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  div {
    flex: 1;
  }
}

@media (max-width: $mobile-break-point) {
  .content {
    padding: $mobile-padding;
  }
  .card {
    padding: $mobile-padding;
    flex-direction: column;
  }

  .card_left {
    display: none;
  }
  .top {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .card_right {
    width: 100%;
    align-items: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .instructions {
    text-align: left;
  }
}
