@import "../../../Styles/theme.scss";

.motion_stand_top {
  display: flex;
  flex-direction: column;
  flex: 2;
  h2,
  p,
  ol {
    color: $neutral7;
  }
}

.image {
  width: 80px;
  object-fit: contain;
  align-self: center;
  margin-top: 40px;
  margin-bottom: 20px;
}
