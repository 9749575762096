@import "../../Styles/theme.scss";

.profile {
  width: 100%;
  position: relative;
  color: $primaryTeal;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: $desktop-padding;
}

.profile_close {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.profile_element {
  width: 50%;
  min-width: 300px;
  min-height: 200px;
  max-width: 400px;
}

.profile_password {
  margin-top: 48px;
}
@media (max-width: $mobile-break-point) {
  .profile {
    padding: $mobile-padding;
    justify-content: center;
  }
}
