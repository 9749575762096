@import "../../Styles/theme.scss";

.wrapper {
  background-color: $white;
  z-index: 90;
  width: 100%;
  padding: 32px 23px;
  box-sizing: border-box;
  max-height: 500px;
  transition: max-height 0.5s ease;
  overflow: hidden;
}

.animationSpacer {
  display: none;
}

.wrapper_collapsed {
  @extend .wrapper;
}
.SUInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.grey_text {
  color: $neutral5;
  font-size: $bodyExtraSmall;
  font-weight: $mediumWeight;
  line-height: 1;
}

.thin_grey_text {
  @extend .grey_text;
  font-weight: $regular;
}

.neutral7_text {
  @extend .grey_text;
  color: $neutral7;
}

.dark_grey_text {
  color: $neutral7;
  font-size: $bodySmall;
  font-weight: $mediumWeight;
  line-height: 1;
}

.SUInfo_left {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.SUInfo_bio {
  display: flex;
  flex-direction: row;
  margin-bottom: 18px;
  flex: 1;
}

.SUInfo_bio_name {
  margin-left: 16px;
  flex: 1;
  h1 {
    margin-top: 0;
    margin-bottom: 12px;
    font-weight: $semiBold;
    font-size: $heading5;
    line-height: 1;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.button {
  color: $primary6;
  margin-bottom: 6px;
  font-size: $bodyExtraSmall;
}

.mobile_conditions {
  display: none;
  margin-bottom: 12px;
}

.mobile_conditions_wrap {
  @extend .mobile_conditions;
  flex-wrap: wrap;
}

.none_listed {
  @extend .grey_text;
  margin-top: -4px;
}

.mobile_conditions_condition {
  line-height: 1;
  padding: 0 5px;
  display: flex;
  align-items: center;
  color: #aa4949;
  font-size: $bodyExtraExtraSmall;
  margin-right: 8px;
  margin-bottom: 6px;
  border-radius: 3px;
  background-color: #f1bcbc;
  height: 20px;
  white-space: nowrap;
  display: flex;
  flex-wrap: wrap;
}

.mobile_conditions_care {
  @extend .mobile_conditions_condition;
  background-color: $oldprimary2;
}

.expand {
  display: none;
  width: 37px;
  min-width: 37px;
  height: 37px;
  border-radius: 50%;
  background-color: $neutral1;
}

.care_type_and_conditions_container {
  margin-left: 67px;
  margin-top: 6px;
  max-width: 100%;
  box-sizing: border-box;
}

.care_contacts_container {
  display: none;
  width: 100%;
  align-items: flex-start;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.margin_bottom {
  margin-bottom: 12px;
}

.margin_top {
  margin-top: 20px;
}

.margin {
  margin: 20px 0;
}

@media (max-width: 600px) {
  .wrapper {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  }
  .wrapper_collapsed {
    max-height: 113px;
  }

  .animationSpacer {
    display: block;
    height: 110px;
  }
  .expand {
    display: block;
  }

  .care_contacts_container {
    display: block;
    margin-top: 14px;
  }

  .mobile_conditions {
    display: flex;
  }

  .SUInfo_bio {
    margin-bottom: 0;
  }

  .SUInfo_bio_name {
    h1 {
      color: $neutral7;
      font-weight: $mediumWeight;
      margin-bottom: 7px;
    }
  }
  .careId {
    display: none;
  }

  .conditions {
    display: none;
  }

  .buttons {
    display: none;
  }

  .button {
    margin-bottom: 0;
  }
}
