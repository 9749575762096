@import "../Styles/theme.scss";

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: 0;
  p {
    margin: 12px 0;
  }
  input {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    border: 1px solid $neutral3;
    border-radius: 6px;
    font-size: $bodyMedium;
  }
}

.resetPasswordOuterContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
    font-size: 36px;
    margin-bottom: 24px;
  }

  img {
    margin-bottom: 48px;
  }
}

.resetPasswordContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
}

.inputInnerContainer {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

// Hide the scrollbar but still allow scrolling
.form::-webkit-scrollbar {
  display: none;
}

.input {
  margin: 6px 0;
}

.submit {
  align-self: flex-end;
  margin-top: 12px !important;
}
.error {
  color: $error3;
}
