@import "../../Styles/theme.scss";

.dashboard_tab {
  padding: 5px 15px !important;
  margin-right: 10px;
  border-radius: 100px !important;
  color: $neutral5;
  background: $white;
  border: 1px solid $neutral2;
  cursor: pointer;
  &:hover {
    background-color: $green3;
    border: 1px solid $green3;
    color: $neutral5;
  }
  white-space: nowrap;
}

.dashboard_tab_icon {
  margin-right: 10px;
}

.dashboard_tab:last-of-type {
  margin-right: 0px;
}

.is_selected {
  @extend .dashboard_tab;
  color: $brandBlack !important;
  background: $green5 !important;
  border: 1px solid $green5 !important;
}
