@import "../../Styles/theme.scss";

.hint {
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background: $white;
  border: 1px solid $neutral2;
  border-radius: 5px;
}
.hint_top {
  display: flex;
  justify-content: space-between;
}

.hint_top_icon {
  display: flex;
}

.hint_description {
  margin-top: 24px;
  font-weight: $regular;
  font-size: $bodyMedium;
  color: $neutral7;
}
