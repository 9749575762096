@import "../../Styles/theme.scss";

.awaiting_install_button_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $brandBlue1;
  border-radius: 4px;
  padding: 15px;
  border: 0.5px solid $neutral2;
  margin-bottom: 16px;
  cursor: pointer;
}

.awaiting_install_text {
  font-size: $bodyMedium;
  color: $neutral7;
  font-weight: $mediumWeight;
}

.click_to_view_text {
  font-size: $bodyExtraSmall;
  color: $neutral7;
}

.awaiting_install_icon_container {
  margin-right: $bodySmall;
}
