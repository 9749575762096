@import "../../Styles/theme.scss";

.bold {
  font-weight: $mediumWeight;
}

.grid {
  display: flex;
  border: 1px solid $neutral2;
  border-radius: 4px;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  color: $neutral7;
  line-height: 18px;
  margin-bottom: 18px;
}

.grid_item {
  margin-top: 10px;
  font-size: $bodySmall;
}

.grid_item_lastSeen {
  @extend .grid_item;
  align-self: flex-end;
}

.grid_roles {
  @extend .grid_item;
  display: flex;
  align-items: center;
  font-weight: $mediumWeight;
}

.grid_roles_role {
  border-radius: 4px;
  padding: 2px 10px;
  background-color: $green5;
  margin-right: 5px;
}
