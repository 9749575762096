@import "../../Styles/theme.scss";

.analysis_container,
.analysis_container_no_data {
  width: 100%;
  flex: 1;
  display: flex;
  padding: 27px;
  background-color: $white;
  flex-direction: column;
  align-items: self-start;
  box-sizing: border-box;
}

.behaviour_wrapper {
  flex: 1;
  width: 100%;
  padding: 27px;
  box-sizing: border-box;
  background-color: $white;
}

@media (max-width: $tablet-sm-break-point) {
  .nav_tab_body {
    width: 95%;
    padding-top: 12px;
  }
  .analysis_container {
    flex-direction: column;
  }
  .behaviour_wrapper {
    flex-direction: column;
  }
}

@media (max-width: $mobile-break-point) {
  .nav_tab_body {
    width: 100%;
    padding-top: 0;
    padding-bottom: 12px;
  }
  .analysis_container,
  .analysis_container_no_data {
    padding: $mobile-padding;
  }
  .behaviour_wrapper {
    padding: 23px;
  }
}
