@import "../../Styles/theme.scss";

.signal {
  font-size: $bodyExtraSmall;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 4px 8px;
  line-height: 16px;
  align-self: flex-start;
}

.connected {
  @extend .signal;
  background-color: $success1;
}

.disconnected {
  @extend .signal;
  background-color: $neutral2;
}
