@import "../../Styles/theme.scss";

.riskStatus {
  display: flex;
  align-items: center;
  div {
    margin-left: 6px;
    margin-top: 4px;
  }
}

.riskStatus_square {
  display: flex;
  align-items: center;
}

.riskStatus_text {
  font-size: $subHeading;
  font-weight: $medium;
}
