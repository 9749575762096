@import "../../Styles/theme.scss";

.list {
  display: flex;
  flex-direction: column;
  list-style: decimal;
  list-style-position: inside;
  text-align: start;
}

.item {
  display: flex;
  flex-direction: column;
  li {
    margin-top: 20px;
  }
  p {
    margin-top: 12px;
    margin-bottom: 0;
  }
}

.image {
  display: flex;
  object-fit: contain;
  align-self: center;
  width: 25%;
  margin-top: 24px;
  margin-bottom: 24px;
}
