@import "../../Styles/theme.scss";

.tab_name {
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: $primaryTeal;
  cursor: pointer;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 1.1rem;
}

.active {
  color: $primaryTeal;
  font-weight: 500;
  line-height: 10px;
}

.active:after {
  position: relative;
  top: 17px;
  content: ""; /* This is necessary for the pseudo element to work. */
  display: block; /* This will put the pseudo element on its own line. */
  margin: 0 auto; /* This will center the border. */
  width: 100%;
  border-bottom: 4px solid $primary3;
  border-radius: 4px;
}

.inactive {
  color: $neutral4;
}

// HACK:
// Inactive tabs gets a 'white' (i.e. invisible) border bottom so that
// the tabs don't "jump up" when switching between tabs.
.inactive:after {
  content: ""; /* This is necessary for the pseudo element to work. */
  display: block; /* This will put the pseudo element on its own line. */
  margin: 0 auto; /* This will center the border. */
  width: 10px;
  border-bottom: 4px solid white;
  border-radius: 4px;
}

// At 1042px, the underline starts to overlap with the wrapped NavTabs
// Reduce the space between the underline and the active tab.
@media (max-width: 1247px) {
  .active:after {
    // border-bottom: 4px solid white; // OR remove the underline
    top: 8px;
  }
}
