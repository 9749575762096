@import "../../Styles/theme.scss";

.filters_and_legend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.legend_details {
  display: flex;
  flex-direction: row;
}

.legend_values {
  font-family: Inter;
  font-size: $bodySmall;
  font-weight: $mediumWeight;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: $neutral5;
}
.legend_values:first-of-type {
  padding-right: 10px;
}
.legend_values:last-of-type {
  padding-left: 10px;
}

.heatmap_legend {
  width: 139px;
  height: 22px;
  background: linear-gradient(to right, #ffffff, $green6);
  border: 1px solid #eaf7f5;
  margin-bottom: 12px;
  border-radius: 13px;
}

.option {
  font-family: Inter;
  font-size: $bodySmall;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: 8px;
  padding: 9px 20px;
  cursor: pointer;
  color: $neutral7;
  font-weight: $mediumWeight;
  border: 1px solid $green5;
  margin-bottom: 12px;
}

.selected {
  @extend .option;
  background-color: $green5;
}

.unselected {
  @extend .option;
  border: 1px solid $neutral2;
}

.container {
  display: flex;
  flex-direction: row;
}

.calendar_wrapper {
  margin-right: 50px;
  h2 {
    font-size: $subHeading;
    margin: 0 0 16px 0;
    color: $neutral7;
  }
}

.calendar_content {
  display: flex;
  flex-direction: column;
}

.calendar_selection {
  font-size: $bodyMedium;
  color: $neutral7;
  margin-bottom: 20px;
}

.selection_options {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  flex-wrap: wrap;
}

.calendar {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 900px;
}

.movement_top_risk_status {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 12px;
}

.movement_risk_status {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-top: 14px;
}

.insights {
  flex: 1;
  line-height: 21px;
  h2 {
    font-size: $subHeading;
    margin: 0 0 16px 0;
    color: $neutral7;
  }
}

.insight_container {
  margin-bottom: 16px;
}

@media (max-width: 1440px) {
  .container {
    flex-direction: column-reverse;
  }
  .calendar {
    margin-bottom: 35px;
  }
  .insights {
    margin-bottom: 36px;
  }
  .insights_container {
    display: flex;
  }
  .insight_container {
    margin-right: 20px;
    margin-bottom: 0px;
  }
  .calendar_wrapper {
    min-width: unset;
    margin-right: unset;
  }
  .calendar {
    min-width: unset;
    margin-right: unset;
  }
  .movement_risk_status {
    width: unset;
  }
}

@media (min-width: 1620px) {
  .calendar_wrapper {
    max-width: unset;
  }
  .calendar {
    max-width: unset !important;
    margin-right: unset !important;
  }
}

@media (max-width: 845px) {
  .filters_and_legend {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 800px) {
  .movement_risk_status {
    flex-direction: column;
    margin-top: 14px;
  }
  .movement_top_risk_status {
    margin-bottom: 8px;
  }
}

@media (max-width: 730px) {
  .insights_container {
    flex-direction: column;
  }
  .insight_container {
    margin-right: 0px;
    margin-bottom: 16px;
  }
}
