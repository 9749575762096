@import "../../Styles/theme.scss";

.rv-xy-plot__axis__title text {
  font-size: $baseFontSize;
  fill: $primaryTeal; // aka $primaryTeal
}

@media (max-width: $mobile-break-point) {
  .rv-xy-plot__axis__title text {
    font-size: $small-font-size;
  }
}
