@import "../../Styles/theme.scss";

.page {
  padding: $desktop-padding;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $neutral1;
  font-family: Inter;
  align-items: flex-start !important;
  position: relative;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  flex: 1;
  padding: $desktop-padding;
  box-sizing: border-box;
}

.card_top {
  position: fixed;
  top: $header-height;
  left: 0;
  right: 0;
  z-index: 100;
}

.card_bottom {
  display: flex;
  flex-direction: row;
  flex: 1;
  h2 {
    color: $neutral7;
  }
}

.card_left {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 24px;
}

.logo {
  width: 33%;
  object-fit: contain;
  align-self: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.images_container {
  display: flex;
  flex-direction: row;
  object-fit: contain;
}

.card_right {
  width: 50%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  h2 {
    margin-top: 24px !important;
  }
}

.logo_mobile {
  display: none;
}

@media (max-width: 900px) {
  .card {
    justify-content: flex-start;
  }
  .card_left {
    display: none;
  }
  .card_right {
    width: 100%;
    flex: 1;
    justify-content: flex-start;
    padding: $mobile-padding;
    h2 {
      color: $neutral7;
    }
    box-sizing: border-box;
  }
  .logo_mobile {
    display: flex;
    width: 50%;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

@media (max-width: $mobile-break-point) {
  .page {
    padding: 0px;
  }
  .card {
    padding: 0;
    flex-direction: column;
  }
  .card_right {
    width: 100%;
    flex: 1;
    justify-content: space-between;
    padding: 22px;
    box-sizing: border-box;
    h2 {
      color: $neutral7;
      margin-top: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  .card_bottom {
    margin-top: 195px;
  }
  .buttons {
    width: 100%;
    flex-direction: column;
  }
}
