@import "../../Styles/theme.scss";

.footer {
  background-color: $sand;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: $mobile-padding;
  img {
    object-fit: contain;
  }
}

.icons {
  align-self: center;
  display: flex;
  img {
    height: 40px;
  }
}

.icons_icon {
  min-width: 50px;
  margin: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  div {
    margin-top: 12px;
    font-weight: bold;
  }
}
