@import "../../Styles/theme.scss";

.user_list_avatar {
  background-color: $yellow;
  width: 32px;
  font-weight: $mediumWeight;
  font-size: $bodyExtraSmall;
  color: $neutral7;
}

.tr {
  background: $white;
  border: 1px solid #e3e3e3;
  border-radius: 0px;
  td {
    flex: 1;
    align-self: center;
    padding: 28px 28px 20px 28px;
    text-align: left;
    font-weight: $regular;
    font-size: $bodyMedium;
    color: $neutral7;
  }
}

.tr:last-child {
  border-radius: 0px 0px 4px 4px;
}

.td_avatar_and_name {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  color: $neutral7;
  font-weight: $mediumWeight;
  font-size: $bodyMedium;

  .td_service_user_name {
    margin-left: 12px;
    cursor: pointer;
  }
}

.td_avatar_and_name:hover {
  .td_service_user_name {
    color: $primary3;
  }
}

.mobile_avatar_and_name_and_care_id {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;

  .mobile_userstring_and_care_id {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    line-height: unset;
  }

  .mobile_userstring {
    color: $neutral7;
    font-weight: $mediumWeight;
    font-size: $bodyMedium;
  }

  .mobile_care_id {
    font-size: $bodyMedium;
    color: $neutral5;
  }
}

.mobile_avatar_and_name_and_care_id:active {
  .mobile_userstring {
    color: $primary3;
  }
}

.mobile_risk_status_text,
.desktop_risk_status {
  display: flex;
  justify-content: center;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: $bodyMedium;
  max-width: 88px;
  color: $neutral7;
}

.at_risk {
  background-color: $atrisktag;
}

.unexpected {
  background-color: $unexpected3;
}

.expected {
  background-color: $white;
  border: 1px solid $neutral3;
}

.no_risk_status {
  background-color: "transparent";
}

.td_staff {
  display: flex;
  flex-direction: row;
  overflow: wrap;
  position: relative;
}

.more_than_six_viewers:hover {
  color: $primary3;
}

.td_staff .more_than_six_viewers_tooltip {
  visibility: hidden;
  text-align: left;
  position: absolute;
  width: 100%;
  top: 60px;
  bottom: 40%;
  left: 20px;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 1;
}

.td_staff:hover .more_than_six_viewers_tooltip {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 400px) {
  .tr {
    td {
      padding: 12px;
    }
  }
}

@media (max-width: 320px) {
  .mobile_avatar {
    display: none;
  }
}

@media (max-width: 300px) {
  .td_mobile_risk_status,
  .td_mobile_installation_status {
    display: none;
  }
}
