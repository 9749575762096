@import "../../Styles/theme.scss";

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: 0;
  p {
    margin: 12px 0;
  }
  input {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    border: 1px solid $neutral2;
    border-radius: 2px;
    font-size: $bodySmall;
    background-color: $neutral1;
    margin-top: 6px;
  }
  label {
    font-size: $bodyMedium;
    color: $neutral7;
  }
}

// Hide the scrollbar but still allow scrolling
.form::-webkit-scrollbar {
  display: none;
}

.input {
  margin: 6px 0;
}

.error {
  color: $error3;
  font-size: $bodySmall;
}
