@import "../../Styles/theme.scss";

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  p {
    margin: 12px 0;
  }
}

// Hide the scrollbar but still allow scrolling
.form::-webkit-scrollbar {
  display: none;
}

.form_fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.field {
  div {
    font-size: $bodySmall;
  }
  .label {
    color: $neutral7;
  }
}

.field_check {
  display: flex;
  font-size: $bodySmall;
  align-items: center;
  padding-top: 24px;
  span {
    margin-left: 6px;
    color: $neutral7;
  }
}

.input {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  border: 1px solid $neutral2;
  border-radius: 2px;
  font-size: $bodySmall;
  background-color: #fafafa;
}

.input_dob {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  border: none;
  font-size: $bodySmall;
  background-color: #fafafa;
}

.field_dob {
  padding-left: 12px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $neutral2;
  border-radius: 2px;
  font-size: $bodySmall;
  background-color: #fafafa;
}

.submit {
  align-self: flex-end;
  margin-top: 12px !important;
}
.error {
  color: red;
  font-size: $bodyExtraSmall;
}

.tip {
  font-size: $bodyExtraSmall;
  color: $neutral5;
}

.checkbox:checked ~ {
  background-color: red;
}

.users {
  display: flex;
  flex-wrap: wrap;
}

.user {
  padding: 6px;
  margin-right: 10px;
  margin-top: 10px;
  border: 1px solid $neutral2;
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.deleteStaff {
  width: 15px;
  cursor: pointer;
  color: $neutral4;
  margin-right: 6px;
}

.warning {
  margin-top: 12px;
}

@media (max-width: $tablet-break-point) {
  .form_fields {
    grid-template-columns: 1fr;
  }
}
