@import "../../../Styles/theme.scss";

.motion_top {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.installation_options_header {
  font-weight: $mediumWeight;
  font-size: $body;
  margin-top: 20px;
  margin-bottom: 20px;
}

.sensors_title,
.installation_options_header,
.option_title,
.option_body {
  color: $neutral7;
}

.sensors_title {
  font-weight: $regular;
  font-size: $bodySmall;
  margin-top: 12px;
  margin-bottom: 12px;
}

.option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: row;
  cursor: pointer;
  padding: 16px;
  border: 0.5px solid $neutral2;
  border-radius: 12px;
  margin-bottom: 12px;
  min-height: 80px;
  max-height: auto;
  img {
    min-width: 60px;
    max-width: 80px;
    max-height: 60px;
    object-fit: contain;
    width: 100%;
    margin-right: 16px;
  }
}

.selected {
  @extend .option;
  border: 1px solid $primary6;
  .option_title {
    color: $primary6;
  }
}

.image_container {
  max-width: 120px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.option_title {
  font-weight: $mediumWeight;
  font-size: $bodyMedium;
}

.option_body {
  font-weight: $regular;
  font-size: $bodyMedium;
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
}

.buttons_button {
  width: 40%;
}

@media (max-width: $mobile-break-point) {
  .buttons {
    flex-direction: column-reverse;
  }
  .buttons_button {
    width: 100%;
  }
}
