@import "../../Styles/theme.scss";

.doughnut {
  width: 80px;
  height: 80px;
  position: relative;
}

.image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  min-width: 50px;
  height: 50px;
  padding: 2px;
  border: none;
}

.chart {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}

.icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  bottom: 0;
  background-color: $white;
  border: 1.5px solid $primaryTeal;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-sizing: border-box;
}
