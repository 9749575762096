@import "../../Styles/theme.scss";

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: 0;
  p {
    margin: 12px 0;
  }
}
// Hide the scrollbar but still allow scrolling
.form::-webkit-scrollbar {
  display: none;
}

.hyphen_group {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  color: $neutral4;
}

.hyphen_no_error {
  padding-top: 21px;
}

.hyphen_error {
  padding-top: 0px;
}

.invisible {
  opacity: 0;
}

.first_row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.date_range {
  display: grid;
  grid-template-columns: 2fr 0.01fr 2fr;
  gap: 8px;
}

.date_range {
  margin-top: 24px;
}

.field {
  font-size: $bodySmall;
  font-family: Inter;
  font-weight: 400;
  color: $neutral7;
  .report_name {
    color: $neutral7;
  }
  input:focus {
    color: $neutral7;
  }
}

.report_period_explanation {
  margin-top: 6px;
  font-size: 14px;
  color: $neutral7;
  font-family: Inter;
  font-weight: 400;
}

.behaviour_title {
  margin-top: 24px;
  font-size: 14px;
  color: $neutral7;
  font-family: Inter;
  font-weight: 400;
}

.behaviours_group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 18px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.behaviour_option {
  display: flex;
  align-items: center;
  padding-top: 9px;
  padding-right: 20px;
  font-size: $bodyMedium;
  color: $neutral7;
  font-family: Inter;
  span {
    margin-left: 6px;
  }
  label {
    margin-left: 5px;
  }
}

.disabled_behaviour_option {
  @extend .behaviour_option;
  color: $neutral5;
}

.behaviour_option:last-of-type {
  padding-right: 0px;
}

.report_name {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  border: 1px solid $neutral2;
  border-radius: 2px;
  font-size: $bodySmall;
  background-color: #fafafa;
  color: $neutral5;
}

.date_input_group {
  padding-left: 12px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $neutral2;
  border-radius: 2px;
  font-size: $bodySmall;
  background-color: #fafafa;
}

.date_selection {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  border: none;
  font-size: $bodySmall;
  background-color: #fafafa;
  color: $neutral5;
  text-transform: uppercase;
  font-family: Inter;
  text-align: left;
}
input[type="date"]::-webkit-date-and-time-value {
  // Necessary for Safari
  text-align: left;
  color: $neutral5;
}
input[type="date"]::-webkit-input-placeholder {
  color: $neutral5;
}
// change the position of the datepicker/calendar icon
// https://stackoverflow.com/questions/63121577/how-do-i-change-the-position-of-the-datepicker-calendar-icon-in-my-bootstrap-for
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="checkbox"] {
  width: 16px;
  height: 16px;
}

.submit_button {
  align-self: flex-end;
}

.coming_soon_container {
  display: flex;
  flex-direction: column;
  border: 1px solid #f2f2f2;
  padding: 16px;
  margin-bottom: 25px;
}

.coming_soon_header {
  font-family: "Inter";
  font-size: $subHeading;
  font-weight: $mediumWeight;
  line-height: $mediumWide;
  letter-spacing: 0em;
  text-align: left;
  color: $neutral7;
}

.coming_soon_text {
  font-family: "Inter";
  font-size: $bodyMedium;
  font-weight: $regular;
  color: $neutral5;
  line-height: $mediumWide;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 9px;
}

.medium_bold {
  font-weight: $mediumWeight;
}

@media (max-width: $tablet-break-point) {
  .first_row {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1260px) {
  .behaviours_group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 965px) {
  .behaviours_group {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 720px) {
  .date_range {
    grid-template-columns: 1fr;
    gap: 24px;
  }
}

@media (max-width: 700px) {
  .behaviours_group {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 600px) {
  .behaviours_group {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 456px) {
  .behaviours_group {
    grid-template-columns: 1fr;
  }
}

/// OLD
#error_message {
  font-size: $bodyExtraSmall;
  color: $flamingo;
  font-weight: $mediumWeight;
}
