@import "../../Styles/theme.scss";

.contextClass {
  margin-top: 24px;
  font-size: $bodyMedium;
}

.page {
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.content {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
}

.header {
  margin-bottom: 12px;
  width: 100%;
  background: $white;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 11px;
  margin-top: 18px;
}

.header_search {
  background: $neutral1 !important;
  border: 1px solid $neutral1 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  width: 280px !important;
  height: 42px !important;
  input {
    background: $neutral1 !important;
    font-size: $bodyMedium;
  }
  input::placeholder {
    font-size: $bodyMedium;
  }
  svg {
    top: 8px;
  }
}

.mobile_sort {
  display: none !important;
}

.no_reports_msg {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.table {
  width: 100%;
  border: 1px solid $neutral2;
  border-radius: 4px;
}

.table_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  padding: 27px 12px;
  border: 1px solid $neutral2;
  span,
  th {
    font-size: $bodyMedium;
  }
}

.table_header_element {
  font-size: $bodySmall;
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 12px;
  max-width: 150px;
  min-width: 90px;
}

.table_header_gap {
  flex: 1;
}

.table_header_sortable {
  @extend .table_header_element;
  cursor: pointer;
  span {
    margin-right: 8px;
  }
}

.table_header_buttons {
  min-width: 455px;
  margin-right: 0 !important;
  th {
    flex: none;
  }
}

.mobile_header {
  background-color: $white;
  padding: 18px 27px;
  border: 1px solid $neutral2;
  border-radius: 4px 4px 0px 0px;
}

.sort {
  background-color: $white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 400;
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.sort_line {
  display: flex;
  justify-content: space-between;
  padding: 13px 26px;
}

.sort_header {
  @extend .sort_line;
  border-bottom: 1px solid $neutral1;
}

.sort_option {
  @extend .sort_line;
}

@media (max-width: 1100px) {
  .content {
    padding: 13px;
    h1 {
      margin-bottom: 28px;
    }
  }

  .header {
    margin-top: 0;
    flex-direction: column;
    padding: 16px;
  }
  .header_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .header_search {
    margin-right: 16px;
    flex: 1;
  }

  .mobile_sort {
    display: flex !important;
  }
}
