@import "../../../Styles/theme.scss";

.page {
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.card {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  box-sizing: border-box;
  position: relative;
  color: $neutral7;
}

.card_bottom {
  display: flex;
  flex-direction: row;

  flex: 1;
}

.card_left {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 73px;
  object-fit: contain;
  align-self: center;
  margin-top: 67px;
  margin-bottom: 20px;
}

.card_right {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  h2 {
    font-weight: 500;
    font-size: $heading2;
    line-height: 36px;
    margin-top: 18px;
  }
}

.sensors {
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  h2 {
    margin-top: 0;
  }
  h4 {
    margin-top: 0;
    font-size: 14px;
    font-weight: 400;
  }
}

.error {
  text-align: center;
  align-self: center;
  margin-bottom: 48px;
  margin-top: 24px;
  div {
    font-weight: 700;
  }
  a {
    text-decoration: underline;
  }
}

.buttons {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 12px;
}

.video_placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $neutral2;
  height: 400px;
  border-radius: 4px;
}

@media (max-width: $mobile-break-point) {
  .content {
    padding: $mobile-padding;
  }
  .card {
    flex-direction: column;
  }
  .card_top {
    position: fixed;
    top: $header-height;
    left: 0;
    right: 0;
    z-index: 100;
  }
  .card_bottom {
    margin-top: 190px;
  }
  .card_left {
    display: none;
  }
  .card_right {
    width: 100%;
    flex: 1;
    justify-content: space-between;
    align-items: stretch;
    padding: 20px;
    margin-top: 0px;
  }
  .buttons {
    width: 100%;
    flex-direction: column;
  }
}
