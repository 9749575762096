@import "../../Styles/theme.scss";

.input {
  font-family: Inter;
  width: 100%;
  box-sizing: border-box;
  color: $neutral7;
  padding: 10px;
  border: 1px solid $neutral2;
  background-color: $neutral1;
  margin-top: 8px;
}

.select_appliacnce {
  margin-top: 14px;
}

.appliance_other {
  margin-top: 20px;
  margin-bottom: 16px;
}
