@import "../../Styles/theme.scss";

.container {
  display: flex;
  flex-direction: column;
  background-color: $neutral7;
  align-items: center;
  width: 160px;
  padding: 2px;
  border-radius: 6px;
  opacity: 0.85;
  p {
    color: $white;
    font-size: 12px;
    margin: 0;
  }
}
