@import "../../Styles/theme.scss";

.filter {
  display: flex;
  align-items: center;
  color: $white;

  border-radius: 16px;
  padding: 3px 8px;
  margin: 4px;
  font-weight: bold;
  div {
    margin-top: 2px;
  }
}

.coral {
  background-color: $primaryCoral;
}

.teal {
  background-color: $primaryTeal;
}
