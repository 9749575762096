@import "../../../Styles/theme.scss";

// The rest
.page {
  padding: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $neutral1;
  align-items: flex-start;
}

.card_left {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 73px;
  object-fit: contain;
}

.card_right {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  background-color: $white;
  flex: 1;
}

.card_right_second {
  background-color: $white;
}

.content {
  padding: 24px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.instructions {
  color: $white;
  p {
    font-size: $body;
  }
}

.instructions_second {
  p {
    color: $neutral7;
  }
  h2 {
    color: $neutral7;
  }
}

.instructions_header {
  color: $neutral7;
  margin-top: 48px;
  margin-bottom: 32px;
  h2 {
    margin: 0;
    font-size: $heading2;
    line-height: 36px;
  }
}

.instructions_secondary_text {
  color: $neutral7;
}

@media (max-width: $mobile-break-point) {
  .page {
    padding: 0;
    background-color: $white;
  }
  .card_left {
    display: none;
  }
  .card_right {
    width: 100%;
    flex: 1;
    justify-content: space-between;
  }
}
