@import "../../Styles/theme.scss";

.dashboard_buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 5;
}

.dashboard_button {
  margin-left: 6px;
  background-color: $green5;
  border: 3px solid $green5;
  color: $neutral7;
  font-weight: $regular;
  &:hover {
    background-color: $primary4;
    border: 3px solid $primary4;
  }
}
// exclude the first child
.dashboard_button:first-child {
  margin-left: 0;
}
