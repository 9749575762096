@import "../../Styles/theme.scss";

.sensor_container {
  display: flex;
  align-items: center;
  align-content: center;
  padding: 30px 22px 22px 22px;
  border-bottom: 1px solid #eeeeee;
  background-color: $white;
}

.details_container {
  display: flex;
  flex-direction: column;
}

.sensor_name {
  font-weight: $mediumWeight;
  font-size: $subHeading;
  color: $neutral7;
  margin-bottom: 8px;
}
