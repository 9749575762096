@import "../../../Styles/theme.scss";

.motion_adhesive_top {
  display: flex;
  flex-direction: column;
  flex: 2;
  h2,
  p,
  ol {
    color: $neutral7;
  }
}
