@import "../../Styles/theme.scss";

.page {
  padding: 24px;
  color: $neutral7;
}

.explanation {
  margin-top: 16px;
  max-width: 800px;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 16px;
}

.notification_date_container {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
}

.notification {
  margin: 8px 0;
  display: flex;
  align-items: center;
  align-self: flex-start;
}

.notification_body {
  display: flex;
  align-items: center;
}

.notifications_for_one_day {
  color: $neutral7;
  font-size: $body;
}

.notification_unread {
  width: 8px;
  height: 8px;
  min-width: 8px;
  background-color: $primaryCoral;
  margin-top: 6px;
  border-radius: 50%;
  margin-right: 12px;
}

.notification_image {
  margin-right: 12px;
}

@media (max-width: $mobile-break-point) {
  .page {
    padding: 13px;
  }

  .notification {
    flex-direction: column;
    align-items: flex-start;
  }

  .notification_button {
    margin-left: 50px;
  }
}
