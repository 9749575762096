@import "../../Styles/theme.scss";

.header {
  display: flex;
  background-color: $primaryTeal;
  color: $white;
  justify-content: space-between;
  padding: $pdf-padding $pdf-padding;
  h2 {
    color: $white;
    width: 200px;
    margin: 0;
    font-size: $heading-3;
  }
  img {
    object-fit: contain;
  }
}

.date {
  background-color: $forest-green;
  color: $white;
  padding: $mobile-padding $pdf-padding;
}

.summary {
  padding: $pdf-padding $pdf-padding;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: $pdf-padding;
  box-sizing: border-box;
}
.summary_left {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 18px;
  h2 {
    margin: 0;
  }
}

.summary_left_name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    font-size: $heading-3;
  }
}

.summary_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h2 {
    margin: 0;
    font-size: $baseFontSize;
  }
}

.summary_right_summary {
  margin-top: 12px;
}

.summary_right_notes {
  border-radius: 12px;
  margin-top: 12px;
  padding: 12px;
  background-color: $white;
  h2 {
    margin: 0;
    font-size: $baseFontSize;
    color: $primaryCoral;
    font-weight: 500;
  }
}

.summary_right_notes_title {
  margin-bottom: 3px;
}

.content {
  background-color: $white;
  flex: 1;
  display: flex;
  padding: $pdf-padding $pdf-padding;
}

.content_block {
  flex: 1;
}

.content_chart {
  height: 180px;
}

.overview {
  display: flex;
}

.overview_pieChart {
  width: 100px;
  height: 100px;
}
