@import "../../../Styles/theme.scss";

.instructions {
  display: flex;
  flex-direction: column;
}

.header_instructions {
  font-weight: $mediumWeight;
  font-size: $heading2;
  color: $neutral7;
  margin-top: 24px;
  margin-bottom: 0;
}

.instructions_steps {
  list-style: decimal;
  margin: 24px 24px 36px 24px;
  text-align: start;
  color: $neutral7;
  font-size: $bodyMedium;
  font-weight: $regular;
  line-height: 28px;
}

.page {
  padding: $desktop-padding;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $neutral1;
  font-family: Inter;
  align-items: flex-start !important;
}

.card {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: $desktop-padding;
  box-sizing: border-box;
  align-items: stretch;
}

.card_container {
  display: flex;
  flex: 2;
  flex-direction: row;
}

.card_left {
  width: 100%;
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 136px;
  object-fit: contain;
  object-position: left;
  align-self: center;
  margin-bottom: 24px;
  margin-top: 12px;
}

.card_right {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.content {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
}

.mobile {
  display: none;
}

.buttons {
  width: 100%;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: $mobile-break-point) {
  .page {
    background-color: $white;
    padding: 0;
  }
  .card {
    padding: 0;
    flex-direction: column;
  }
  .card_container {
    display: flex;
    flex: 2;
    flex-direction: column;
  }
  .card_right {
    width: 100%;
    flex: 1;
    justify-content: space-evenly;
  }
  .mobile {
    display: block;
  }
  .card_left {
    display: none;
  }
  .instructions_steps {
    margin-top: 0px;
  }
  .buttons {
    width: 100%;
    flex-direction: column-reverse;
  }
}
