@import "../../Styles/theme.scss";

.sensor {
  padding: 20px;
  border: 0.5px solid $neutral2;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  box-sizing: border-box;
  min-width: 250px;
  height: 105px;
  min-height: 105px;
}

.sensor_click {
  cursor: pointer;
}

.sensor_left {
  display: flex;
  align-items: center;
}

.sensor_details {
  display: flex;
  flex-direction: column;
}

.sensor_name {
  line-height: 1.2rem;
  white-space: nowrap;
  font-weight: $mediumWeight;
}

.sensor_location {
  line-height: 0.8rem;
  margin: 2px 0 0 0;
  font-size: $bodyExtraSmall;
  color: $neutral5;
}

.no_sensor_location {
  color: $error2;
  font-weight: $semiBold;
}

.sensor_signal {
  margin-top: 8px;
  align-self: flex-start;
}

.image {
  width: 60px;
  object-fit: contain;
  align-self: center;
}
